import React from 'react'

export default ({ title, subtitle, to, subject, button }) => (
    <section className="apply-form blue">
        <div className="inner">

            <h2>{title}</h2>
            <p>{subtitle}</p>
            <form action={`https://formspree.io/${to}`} name="contact" method="POST">
                <input type="hidden" name="_next" value="https://www.zupr.io/nl/bedankt/" />
                <input type="hidden" name="_subject" value={`${subject}`} />
                <input type="hidden" name="_language" value="nl" />
                <ul>
                    <li><input required type="text" name="name" placeholder="Uw naam" /></li>
                    <li><input type="text" name="organisation" placeholder="Naam verkooplocatie" /></li>
                    <li><input required type="email" validator="" name="email" placeholder="Uw e-mailadres" /></li>
                    <li><button className="btn btn-default" type="submit">{`${button}`}</button></li>
                </ul>
            </form>

        </div>
    </section>
)
