import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../../../layout/layout'

import WatchVideo from '../../../components/watch-video'
import PilotApply from '../../../components/pilot-apply'

import Street from '../../../svg/street.svg'
import Simple from '../../../svg/simple.svg'
import GS1 from '../../../svg/gs1.svg'
import Statistics from '../../../svg/statistics.svg'

const Page = () => (

    <Layout>

        <Helmet>
          <title>Zupr - Maakt producten lokaal vindbaar</title>
          <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
        </Helmet>

        <section className="city street">
            <h1>Zupr maakt producten lokaal vindbaar</h1>
            <p>Zupr is een online platform dat het productaanbod van winkels in kaart brengt en deelt met partijen die op internet willen promoten waar (of welke) producten in de buurt te koop zijn.</p>
            <WatchVideo id="kIcDyWA7cRo">
                Bekijk video
            </WatchVideo>
            <a className="btn btn-demo" href="mailto:demo@zupr.io?subject=Ik wil graag een demo">Live demo aanvragen</a>
            <div className="background"><Street /></div>
        </section>

        <section className="text-default divider">
            <div className="inner">

                <div className="text">
                    <h2>De voordelen voor uw merk op een rij</h2>
                    <ul className="check-list">
                        <li>Profiteer van de sterke groei in online vraag naar lokaal aanbod.</li>
                        <li>Verrijk uw store locators en online campagnes met voorraaddata geleverd door winkels.</li>
                        <li>Krijg toegang tot de online kanalen van partijen die uw producten of verkooppunten promoten.</li>
                        <li>Verhoog uw serviceniveau richting zowel consumenten als lokale winkeliers/dealers.</li>
                        <li>Houd controle over uw productdata en branding in de communicatie met consumenten.</li>
                        <li>Benut krachtige statistieken om lokale vraag en aanbod beter op elkaar af te stemmen.</li>
                        <li>Het aanleveren van productgegevens bij Zupr via GS1 Data Link is gratis.</li>
                    </ul>
                </div>

            </div>
        </section>

        <section className="contact-boost divider">
            <div className="inner">
                <h2>Meer weten? Lees verder of neem contact met ons op:</h2>
                <p>
                    <a href="mailto:info@zupr.io?subject=Interesse in Zupr" className="email">info@zupr.io</a>
                    <a href="tel:+31854011306" className="telephone">+31 (0)85 401 13 06</a>
                </p>
            </div>
        </section>

        <section className="text-center">
            <div className="inner">

                <div className="text">
                    <h2>500% groei in online vraag naar lokaal aanbod!</h2>
                    <p>Consumenten zoeken online steeds vaker waar producten in de buurt te koop zijn. Volgens Google is dit in twee jaar tijd met maar liefst 500% toegenomen (<a href="https://www.thinkwithgoogle.com/consumer-insights/near-me-searches/" rel="noopener noreferrer" target="_blank">link</a>). Zupr zorgt dat merken en retailers op die groeiende behoefte in kunnen spelen. Door de uitwisseling van product- en locatiegegevens te versimpelen én de locaties van producten via nieuwe en bestaande online kanalen te ontsluiten richting consumenten.</p>
                </div>

            </div>
        </section>

        <section className="text-center grey">
            <div className="inner">

                <div className="text">
                    <h2>Hoe werkt Zupr</h2>
                    <p>Merken geven hun productgegevens gratis door aan Zupr, zodat retailers deze gegevens niet zelf hoeven aan te leveren. Winkels geven vervolgens kosteloos aan Zupr door wat ze verkopen om andere partijen, bijvoorbeeld merken en steden, inzicht te geven in hun assortiment en voorraad.</p>
                    <p>Zupr onderscheidt zich van andere retailplatformen door de lokale beschikbaarheid van producten te delen met partijen die dit willen promoten.</p>
                    <h3>Store locator</h3>
                    <p>Als merk kunt u met deze data in de <strong>store locator</strong> op uw website of vanuit <strong>online marketingcampagnes</strong> direct tonen waar een specifiek product in de buurt van de consument op voorraad is.</p>
                    <h3>Retailplatform</h3>
                    <p>Steden en winkelgebieden gebruiken de door Zupr ontwikkelde <strong>retailplatform</strong> om via hun eigen online kanalen richting bewoners en bezoekers te kunnen promoten wat er in lokale winkels te koop is.</p>
                    <p><strong>Consumenten die online een product zoeken, kunnen zo direct zien welke lokale verkooppunten er zijn!</strong></p>
                    <WatchVideo id="0vKJYBrj-0o">
                        Zupr voor winkeliers
                    </WatchVideo>
                    <WatchVideo id="abRyUwyTVlE">
                        Collectief retailplatform
                    </WatchVideo>
                </div>

            </div>
        </section>

        <section className="text-left divider">
            <div className="inner">

                <div className="text">
                    <h2>Hoe we GS1 Data Link gebruiken</h2>
                    <p>Winkels geven hun assortiment en voorraad aan Zupr door op basis van o.a. het unieke Global Trade Item Number (GTIN) van een product. Voor elke GTIN zoeken wij in meerdere databronnen naar de bijbehorende artikelgegevens om consumenten correct te kunnen informeren. We gebruiken <strong>GS1 Data Link</strong> als een primaire bron vanwege de hoogwaardige productdata die rechtstreeks afkomstig is van fabrikanten.</p>
                </div>

                <div className="image gs1">
                    <GS1 />
                </div>

            </div>
        </section>

        <section className="text-right divider">
            <div className="inner">

                <div className="text">
                    <h2>Controle over uw productdata & branding</h2>
                    <p>Doordat winkeliers zelf geen productgegevens hoeven in te voeren, is Zupr eenvoudig in gebruik en toegankelijk voor elke winkel. Bijkomend voordeel is, dat u als merk de controle heeft over de artikeldata, productbeschrijvingen en -afbeeldingen die consumenten onder ogen krijgen. Zo weet u zeker dat deze altijd accuraat zijn en uw merk via elk kanaal op de juiste manier wordt uitgedragen.</p>
                </div>

                <div className="image">
                    <Simple />
                </div>

            </div>
        </section>

        <section className="text-center">
            <div className="inner">

                <div className="text">
                    <h2>Analyse & inzicht in lokale vraag en aanbod</h2>
                    <p>Van alle ‘digital touch points’ houden wij uitgebreide statistieken bij. Centraal staat de geolocatie van de gebruiker die op zoek is naar een lokaal verkooppunt. Hiermee verkrijgt u o.a. zeer fijnmazig <strong>inzicht in hoe de lokale vraag naar uw producten zich verhoudt tot de lokale beschikbaarheid.</strong> (onderdeel van betaald abonnement)</p>
                </div>

                <div className="image no-margin">
                    <Statistics />
                </div>

            </div>
        </section>

        <section className="text-center">
            <div className="inner">

                <div className="text">
                    <h2>Verdienmodel Zupr</h2>
                    <p>Het aanleveren van productgegevens door merken is gratis en ook retailers kunnen hun assortiment en voorraad kosteloos doorgeven. Zupr combineert de ontvangen product- en locatiegegevens tot “productlocaties”. Pas als partijen in hun eigen toepassingen (bijv. in store locators, campagnes, websites, apps of retailplatform) gebruik maken van deze “productlocaties” of de statistieken die ermee worden verzameld, rekent Zupr kosten.</p>
                </div>

            </div>
        </section>

        <a name="apply-bottom"></a>

        <PilotApply title="Wilt u meer weten over onze store locator technologie?" subtitle="Laat uw gegevens achter en een medewerker van Zupr neemt binnen twee werkdagen contact met u op." to="info@zupr.nl" subject="GS1" button="Versturen" />

        <section className="contact-boost">
            <div className="inner">
                <h2>Liever zelf contact met ons opnemen?</h2>
                <p>
                    <a href="mailto:info@zupr.io?subject=Interesse in Zupr" className="email">info@zupr.io</a>
                    <a href="tel:+31854011306" className="telephone">+31 (0)85 401 13 06</a>
                </p>
            </div>
        </section>

    </Layout>
)

export default Page
